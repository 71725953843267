import sassColorsExport from '../sass/pixy.vars.scss';

window.sassColors = sassColorsExport;

$(function() {

    // portfolio boxy
    $('.grid a.item-link')
    .on('mouseenter',function () {
        var $this = $(this)
        setTimeout(function() {
            $this.addClass('hovered')
        },10);
    })
    .on('mouseleave',function () {
        $(this).removeClass('hovered')
    })
    .on('click',function (e) {
        if (!$(this).hasClass('hovered')) {
            e.preventDefault()
            var $this = $(this)
            setTimeout(function() {
                $this.addClass('hovered')
            },10);
            return false;
        }
    })

    // anchor boxy
    $('.service-holder.anchor')
    .on('click', function(){
        var $e = $(this).find('a.slow-scroll');
        $e.trigger('click')
    })
    $('.service-holder.anchor a')
    .on('click', function(e){
        e.stopPropagation();
    })

    // lightbox
    var lightbox = new SimpleLightbox('a.lightbox', {
        preloading: false,
        alertError: false,
        animationSlide: false,
        widthRatio: 0.95,
        heightRatio: 0.95
    });

})
